// import { Injectable } from '@angular/core';
// import { CanActivate } from '@angular/router';
// import { Router } from '@angular/router';

// @Injectable()
// export class AuthGuard implements CanActivate {
//     constructor(private router: Router) {}

//     canActivate() {
//         if (localStorage.getItem('isLoggedin')) {
//             return true;
//         }

//         this.router.navigate(['/login']);
//         return false;
//     }
// }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
        private _auth_srv: AuthService
        ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Subject<boolean> {
          const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
          if (sessionStorage.getItem('isLoggedin')) {
              if (this._auth_srv.check_session_time()) {

                  this._auth_srv.authorize(state.url, route.data).asObservable().subscribe( (allowed: boolean) => {
                      subject.next(allowed);
                      subject.complete();
                      if (!allowed) {
                          this.router.navigate(['/login']);
                      }
                  });

              } else {
                  subject.next(false);
                  subject.complete();
                  this.savePathsToNavigateOnLogin(state.url);
                  this.router.navigate(['/login']);
              }

              subject.next(true);
              subject.complete();
              this.navigateToSavedPathIfExist();
          } else {
              subject.next(false);
              subject.complete();
              this.savePathsToNavigateOnLogin(state.url);
              this.router.navigate(['/login']);
          }

          return subject;

      }

      /**
       * Guarda el path al que intenta ir el usuario para llevarlo una vez compente la autenticación
       * @param path
       */
      private savePathsToNavigateOnLogin(path){
          if(path.includes('/clientes/detalle-auditoria')) {
              localStorage.setItem("pathToNavigate",path);
          }
      }
      /**
       * Ejecuta la redirección de navegación si se encuentra guardada una ruta y el usuario inicio sesion.
       */
      private navigateToSavedPathIfExist(){
          if(localStorage.getItem("pathToNavigate")!==null){
              let url = localStorage.getItem("pathToNavigate");
              localStorage.removeItem("pathToNavigate");
              this.router.navigateByUrl(url);
          }
      }
  }
