import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { Data, Router } from '@angular/router';
import swal from 'sweetalert2';
import { AUTENTICACION_MICROSOFT, RECUPERAR_CONTRASENA, Url_loggin, Url_parametricos_user } from './services.config';




// =========================== Declarando las interfaces para el componente login =================================================

interface BaseUserAuthResponse {
    count: Number;
    next?: string;
    previous?: string;
    results: Array<UserAuth>;
}

export class UserAuth {
    id: number;
    last_login: Date;
    is_superuser: boolean;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_staff: false;
    is_active: true;
    date_joined: Date;
    groups: Array<number>;
    user_permissions: Array<number>;
}


export class AuthRequest {
    public username: string;
    public password: string;
}
export class AuthResponse {
    public key: string;
}

/**
 * Creando la numeracion de los roles del sistema
 */
export enum USUARIO {
    ABOGADO_PARAMETRIZADOR = 4,
    ABOGADO_LIDER,
    CLIENTE_LIDER,
    CLIENTE_ENCUESTADO,
    ABOGADO_AUDITORIAS

  }
// =================================================================================================================================
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    // httpSrv: any;

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private httpSrv: HttpClient,
       ) { }

    // SE COMENTA A MANERA DE PRUEBA PARA QUE NO SE CIERRE LA SECION public default_session_time_min = 10;
    public get USER_GROUP_ABOGADO_PARAMETRIZADOR() { return 4; }
    public get USER_GROUP_ABOGADO_LIDER() { return 5; }
    public get USER_GROUP_CLIENTE_LIDER() { return 6; }
    public get USER_GROUP_CLIENTE_ENCUESTADO() { return 7; }
    public get USER_GROUP_ABOGADO_AUDITORIAS() { return 8; }

    /**
     * Metodo para realizar el consumo del servicio Login
     */
    public login(auth: AuthRequest) {
        // console.log("Auth en el servicio", auth);
      /*   || 'auxiliar' || 'asistente' || 'comite'*/
     // console.log('este es la respuesta del login', auth);
     /*  if (this.httpClient.post === "400"){

      } */
    return this.httpClient.post(Url_loggin, auth);
}
    /* } else {
        swal.fire('Usuario Incorrecto!');
    } */



    public register_login(authResponse: AuthResponse) {

     /*    if (authResponse === null) {console.log('LOGIN INCORRECTO'); } else {
        console.log('esta es la respuesta', authResponse); */
        // console.log("authResponse en register login service auth",authResponse);
        sessionStorage.clear();
        const login_date_time = new Date();
        sessionStorage.setItem('isLoggedin', 'true');

        sessionStorage.setItem('drf_token', authResponse.key);
        sessionStorage.setItem('session_time_update', login_date_time.toString());
        sessionStorage.removeItem('UserAuth');
        // SE DEBE HABILITAR CUANDO SE ACTIVE LA RUTA USER
        // this.getUserAuth().subscribe(
        //     res => { /* console.log(res); */
        //     }
        // );
    }

    public update_session_time() {
        const isLoggedin: Boolean = Boolean(sessionStorage.getItem('isLoggedin'));
        if (isLoggedin) {
            const date_time = new Date();
            sessionStorage.setItem('session_time_update', date_time.toString());
            return true;
        } else {
           // console.log('INCORRECTO');
        }
        return false;
    }

    public check_session_time() {

        const session_time_update_data = sessionStorage.getItem('session_time_update');
        if (session_time_update_data !== null) {

            const session_time_update = moment(session_time_update_data);

            const now = moment(new Date()); // todays date

            const duration = moment.duration(now.diff(session_time_update));
            const minutes = duration.asMinutes();
           // console.log(minutes);
        //    comentado a manera de prueba por el cierre de secion
            // if (minutes > this.default_session_time_min) {
            //     sessionStorage.clear();
            //     return false;
            // }

            this.update_session_time();
            return true;


        } else {
            return false;
        }
    }

    public getSecutityHttpHeaders() {
        const token = sessionStorage.getItem('drf_token'); // 'ffb064aec7bbbeabf7e773b8c079a223a0b9676a';
        if (token === null) {
           // console.log('sin token');
           /*  console.error('drf_token not found');
            throw new Error('drf_token not found'); */
            // console.log("Token NULLLLLL");
        } else {
            let headers: HttpHeaders = new HttpHeaders();

            headers = headers.set('Authorization', `Token ${token}`);

            return headers;
        }

    }

    public getUserAuth() {
// console.log("INGRESO A GETUSER");
        const subject: ReplaySubject<UserAuth> = new ReplaySubject<UserAuth>();
        // console.log("INGRESO A GETUSER",subject);
        const userAuthVal = sessionStorage.getItem('UserAuth');
        // console.log("userAuthVal",userAuthVal);
        if (userAuthVal === null) {
            // console.log("ingreso a USERAUTH",userAuthVal);
            const headers: HttpHeaders = this.getSecutityHttpHeaders();
            // console.log("ENVIANDO TOKEN",headers);
            this.httpClient.get<BaseUserAuthResponse>(`${Url_parametricos_user}`, {headers})
                .subscribe((response: BaseUserAuthResponse) => {
                    // console.log("RESPUESTA GETUSER................................................",response);
                    sessionStorage.setItem('UserAuth', JSON.stringify(response.results[0]));
                    subject.next(response.results[0]);
                 });
        } else {
            const userAuth = JSON.parse(userAuthVal);
            subject.next(userAuth);
        }


        return subject;
    }



    public authorize(path: string, routeData: Data) {
        //  console.log("ROUTEDATA , PATCH ENTRO A SOLICITAR AUTORIZACION......",routeData,path);
        const authorizeRoles: Array<string> = routeData.authorizeRoles as Array<string>;
        const authorizeAll: boolean = routeData.authorizeAll as boolean;

        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>();
        let allowed = false;

        if (authorizeRoles === undefined && authorizeAll  === undefined) {
            // console.error(`Se debe especificar authorizeRoles o authorizeAll en la data de la url(${path})`);
            subject.next(false);
            subject.complete();
        } else if (authorizeAll !== undefined && authorizeAll === true) {
            subject.next(true);
            subject.complete();
        } else {
            this.getUserAuth().subscribe(
                (userAuth: UserAuth) => {

                    userAuth.groups.forEach((userGroup: number) => {
                        switch (userGroup) {
                            case this.USER_GROUP_ABOGADO_PARAMETRIZADOR:
                                allowed = allowed || authorizeRoles.includes('abogado_parametrizador');
                                break;
                            case this.USER_GROUP_ABOGADO_LIDER:
                                allowed = allowed || authorizeRoles.includes('abogado_lider');
                                break;
                            case this.USER_GROUP_CLIENTE_LIDER:
                                allowed = allowed || authorizeRoles.includes('cliente_lider');
                                break;
                            case this.USER_GROUP_CLIENTE_ENCUESTADO:
                                allowed = allowed || authorizeRoles.includes('cliente_encuestado');
                                break;
                                case this.USER_GROUP_ABOGADO_AUDITORIAS:
                                    allowed = allowed || authorizeRoles.includes('abogado_auditorias');
                                    break;
                            default:
                                break;
                        }
                    });

                    if (allowed === false) {
                        // Esto se debe borrar, es solo de prueba para saber que pagina no tiene permiso de navegacion
                       console.log(`El usuario ${userAuth.username} ${userAuth.email}no cuenta
                   con acceso a ${path} con los Grupos ${JSON.stringify(userAuth.groups)}`);
                    }

                    subject.next(allowed);
                    subject.complete();
                },
                (err) => {
                    subject.next(false);
                    subject.complete();
                }

            );
        }
        // se retorna true en prueba, remplazar por :subject
        return subject;
    }

    validateAuthAccess(AllowedGroups: Array<string>): ReplaySubject<boolean> {

        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>();
        // '', 'abogado_parametrizador', 'abogado_lider', 'cliente_lider', 'cliente_encuestado','abogado_auditorias'
        // public get USER_GROUP_ABOGADO_PARAMETRIZADOR() { return 4; }
        // public get USER_GROUP_ABOGADO_LIDER() { return 5; }
        // public get USER_GROUP_CLIENTE_LIDER() { return 6; }
        // public get USER_GROUP_CLIENTE_ENCUESTADO() { return 7; }
        // public get USER_GROUP_ABOGADO_AUDITORIAS() { return 8; }
        const groupsList = [
            {
                'id': 4,
                'rol': 'abogado_parametrizador'
            }, {
                'id': 5,
                'rol': 'abogado_lider'
            }, {
                'id': 6,
                'rol': 'cliente_lider'
            }, {
                'id': 7,
                'rol': 'cliente_encuestado'
            }, {
                'id': 8,
                'rol': 'abogado_auditorias'
            }
        ];

        this.getUserAuth().subscribe((userAuth: UserAuth) => {
            let can_access = false;
            userAuth.groups.forEach(groupUser => {
                AllowedGroups.forEach(allowedGroup => {
                    can_access = can_access || groupsList.find(rol => rol.rol === allowedGroup)?.id === Number(groupUser);
                });
            });

            subject.next(can_access);
            subject.complete();

        });
        return subject;


    }

    /**
     * Metodo para consumir servicio de cambio de contraseña
     */
    public recuperarContrasena(usuario: string) {
        // console.log('usuariousuariousuariousuario', usuario);
        const headers: HttpHeaders = this.getSecutityHttpHeaders();
        return this.httpClient.post(`${RECUPERAR_CONTRASENA}`, {email: usuario, }, { headers: headers });
        }

    public postLoginMicrosoft(code: string) {

        // const headers: HttpHeaders = this.getSecutityHttpHeaders();
        return this.httpClient.post(`${AUTENTICACION_MICROSOFT}`, {code: code, });

        }

/**
 * Servicio que controla el inicio de sesion de los usuarios en general, usuarios lopez y usuarios del django
 */
public inciarsesion(response) {
// console.log('RESPONSEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE', response);
    return new Observable((observer) => {

        this.register_login(response);
        this.getUserAuth().subscribe((userAuth: UserAuth) => {
            let url;
            if ( userAuth.groups[0] === 5 || userAuth.groups[0] === 8 ) {
               url = ['/dashboard-auditorias'];
            }
            observer.next({userAuth, url});
         }, (error) => {
            observer.error(`error del servicio,${error}`);
        });

    });
    }

}

// ({
//                     next: (InfoCliente) => {
//                         console.log("infocliente",InfoCliente);
//                         if(InfoCliente.length !== 0){
//                             this.router.navigate(['/clientes/auditorias/',InfoCliente[0].cliente_id]);
//                             this.cargandoAutenticacion = false;
//                              }else{
//                                 this.openDialogUsuarioSinCliente();
//                                 //  alert("Usuario sin cliente asociado, solicite al administrador la asignación");
//                                  this.disabledLogin = false;
//                                  this.cargandoAutenticacion = false;
//                              }
//                         }
//                         ,error: (err) => {
//                           console.log(err);
//                         }
