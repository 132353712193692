import { environment } from 'src/environments/environment';

export const API_URL = environment.api_url;
export const AUTH_URL = environment.url_auth;


// export const API_URL = environment.urlBase;

// =====================================Login =======================================================

export const Url_loggin = `${AUTH_URL}rest-auth/login/`;
export const Url_parametricos_user_original = `${AUTH_URL}rest-auth/user/`;
export const Url_parametricos_user = `${AUTH_URL}rest-auth/user_group/`;
export const RECUPERAR_CONTRASENA = `${AUTH_URL}rest-auth/password/reset/`;
export const AUTENTICACION_MICROSOFT = `${AUTH_URL}rest-auth/rest-auth/microsoft/`;


// ====================================== Consumo servicios parametricos ==============================+

export const LISTADO_TIPO_AUDITORIAS_URL = `${API_URL}/parametricos/tipo_auditoria/`;

// ===================================== Consumo servicios encuesta ====================================+

export const ENCUESTA_CLIENTE = `${API_URL}auditoria_cliente/encuesta_usuario_auditoria/`;


// ===================================== Consumo servicios auditorias cliente ====================================+

export const AUDITORIAS_CLIENTE = `${API_URL}auditoria_cliente/cliente/`;
export const AUDITORIAS_CLIENTE_AUDITORIAS = `${API_URL}auditoria_cliente/auditoria/cliente/`;

export const AUDITORIA_CLIENTE = `${API_URL}auditoria_cliente/auditoria/`;

export const AUDITORIA_ETAPAS = `${API_URL}auditoria/etapa/`;
export const AUDITORIA_CONTROL_CAMBIO_ETAPA = `${API_URL}auditoria/cambio_etapa/`;



export const FINALIZAR_ENCUESTA = `${API_URL}auditoria_cliente/finalizar_encuesta/`;

export const REACTIVAR_ENCUESTA = `${API_URL}auditoria_cliente/reactivar_encuesta/`;

export const ENCUESTA_ABOGADOS = `${API_URL}auditoria/encuesta/abogado_auditoria/`;

export const ENCUESTA_USUARIO = `${API_URL}auditoria/encuesta/abogado_auditoria_usuario/`;

export const USUARIOS_TEMA = `${API_URL}auditoria/info/`;

// ======================= GANTT===================================================================================

export const RECURSOS_GANTT = `${API_URL}auditoria/recurso/`;
export const TAREAS_GANTT = `${API_URL}auditoria/`;


// ============================= Bitacora x auditoria =============================================

export const MENSAJES_AUDITORIA = `${API_URL}core/bitacora/`;
export const MENSAJES_GESTIONADO_BITACORA = `${API_URL}core/bitacora/gestion/`;



// ============================== Dashboard Auditoria ==============================================

export const DASHBOARD_AUDITORIA = `${API_URL}auditoria/info/`;
export const AUDITORIA_CAMBIO_ETAPA = `${API_URL}auditoria/info/`;


// http://localhost:8001/api/auditoria/info/109/ CAMBIAR LA ETAPA POST

// ============================ Calculo del rieso =======================================

export const CALCULO_DEL_RIESGO = `${API_URL}auditoria/calcula_riesgo/`;
export const ANALISIS_DEL_RIESGO = `${API_URL}auditoria/analisis_riesgo/`;
export const DETALLE_DEL_RIESGO = `${API_URL}auditoria/detalle_riesgo/`;
export const RIESGO_NIVEL_CONSECUENCIA = `${API_URL}auditoria/riesgo_nivel_consecuencia/`;


// ============================ Grafica cuadro de mando Multiple =======================================

export const GRAFICA_CUADRO_MANDO_MULTIPLE = `${API_URL}auditoria/avance_tema/`;

// ============================= Recomendaciones =======================================================

export const RECOMENDACIONES_AUDITORIA = `${API_URL}auditoria/recomedacion/`;
export const AUDITORIA_RIESGO_TEMA = `${API_URL}auditoria/info/`;
export const ACTUALIZAR_NIVEL_CONSECUENCIA = `${API_URL}auditoria/analisis_riesgo/`;

// ============================= Entregables ===========================================================

export const GUARDAR_ARCHIVO = `${API_URL}core/adjunto/`;
export const LISTADO_ENTREGABLES = `${API_URL}core/adjunto/`;
export const DETALLE_ENTREGABLE_Y_VERSION = `${API_URL}core/adjunto/`;
export const GESTIONAR_VERSION = `${API_URL}core/adjunto/`;
export const GESTIONAR_ENTREGABLE_CLIENTE = `${API_URL}core/adjunto/`;
export const DESCARGAR_ENTREGABLE = `${API_URL}core/adjunto/`;

// ======================= Reportes ====================================================================

export const REPORTE_PREGUNTAS_RESPUESTA = `${API_URL}reportes/pregunta_respuesta/`;
export const REPORTE_RIESGO_PESO = `${API_URL}reportes/riesgo_peso/`;
export const REPORTE_RIESGO_RECOMENDACION = `${API_URL}reportes/riesgo_recomedacion/`;
export const REPORTE_RIESGO_MATRIZ_GENERAL = `${API_URL}reportes/riesgo_matriz/`;
export const REPORTE_COMENTARIO_BITACORA = `${API_URL}reportes/bitacora/`;

